define("ember-table/components/-private/scroll-indicators/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showLeft}}
    <div
      data-test-ember-table-scroll-indicator="left"
      class="scroll-indicator scroll-indicator__left"
      style={{this.leftStyle}}
    ></div>
  {{/if}}
  {{#if this.showRight}}
    <div
      data-test-ember-table-scroll-indicator="right"
      class="scroll-indicator scroll-indicator__right"
      style={{this.rightStyle}}
    ></div>
  {{/if}}
  {{#if this.showTop}}
    <div
      data-test-ember-table-scroll-indicator="top"
      class="scroll-indicator scroll-indicator__top"
      style={{this.topStyle}}
    ></div>
  {{/if}}
  {{#if this.showBottom}}
    <div
      data-test-ember-table-scroll-indicator="bottom"
      class="scroll-indicator scroll-indicator__bottom"
      style={{this.bottomStyle}}
    ></div>
  {{/if}}
  
  */
  {
    "id": "zjY3hHL4",
    "block": "[[[41,[30,0,[\"showLeft\"]],[[[1,\"  \"],[10,0],[14,\"data-test-ember-table-scroll-indicator\",\"left\"],[14,0,\"scroll-indicator scroll-indicator__left\"],[15,5,[30,0,[\"leftStyle\"]]],[12],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showRight\"]],[[[1,\"  \"],[10,0],[14,\"data-test-ember-table-scroll-indicator\",\"right\"],[14,0,\"scroll-indicator scroll-indicator__right\"],[15,5,[30,0,[\"rightStyle\"]]],[12],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showTop\"]],[[[1,\"  \"],[10,0],[14,\"data-test-ember-table-scroll-indicator\",\"top\"],[14,0,\"scroll-indicator scroll-indicator__top\"],[15,5,[30,0,[\"topStyle\"]]],[12],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showBottom\"]],[[[1,\"  \"],[10,0],[14,\"data-test-ember-table-scroll-indicator\",\"bottom\"],[14,0,\"scroll-indicator scroll-indicator__bottom\"],[15,5,[30,0,[\"bottomStyle\"]]],[12],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\"]]",
    "moduleName": "ember-table/components/-private/scroll-indicators/template.hbs",
    "isStrictMode": false
  });
});