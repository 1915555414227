define("ember-testing/index", ["exports", "ember-testing/lib/public-api", "@ember/test"], function (_exports, EmberTesting, _test) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(EmberTesting).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === EmberTesting[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return EmberTesting[key];
      }
    });
  });
  (0, _test.registerTestImplementation)(EmberTesting);
});