define("ember-table/-private/utils/array", ["exports", "@ember/array", "@ember/debug"], function (_exports, _array, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.move = move;
  _exports.objectAt = objectAt;
  _exports.splice = splice;
  /**
    Genericizes `objectAt` so it can be run against a normal array or an Ember array
  
    @param {object|Array} arr
    @param {number} index
    @return {any}
  */
  function objectAt(arr, index) {
    (true && !((0, _array.isArray)(arr) || typeof arr.objectAt === 'function') && (0, _debug.assert)('arr must be an instance of a Javascript Array or implement `objectAt`', (0, _array.isArray)(arr) || typeof arr.objectAt === 'function'));
    if (typeof arr.objectAt === 'function') {
      return arr.objectAt(index);
    }
    return arr[index];
  }
  function splice(items, start, count, ...add) {
    if (typeof items.replace === 'function' && typeof items.objectAt === 'function') {
      return items.replace(start, count, add);
    }
    return items.splice(start, count, ...add);
  }

  /**
   * Cycle shift an internal [start..end] to [start + 1...end, start].
   */
  function move(items, start, end) {
    let sourceItem = objectAt(items, start);
    splice(items, start, 1);
    splice(items, end, 0, sourceItem);
  }
});